.container-styles-portfolio {
    border-radius: 0px;
    width: 80%;
    height: auto;
    margin: 0 auto;
}

.portfolio-background {
    background: rgb(237, 234, 227);
    margin: 0 0px 0 15px;
    border-radius: 0px;
    padding-top: 20px;
  }

.container-portfolio-page.home-background{
    background: rgb(166,153,146);
}

.project-title {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 10px;
    // background:rgb(244, 244, 236);
    border-radius: 0px;
    width: 80%;
}

.background-for-portfolio {
    background: rgb(74, 100, 92);
    background: rgb(197, 207, 198);
    border-radius: 5px;
    // box-shadow: 0 5px 10px 5px rgb(31, 61, 59);
    box-shadow: none;
    width: 80%;
    margin: 0 auto 20px auto;
    padding-top: 15px;
}

.tb-logo {
height: 30px;
padding-right: 10px;
}

h6 {
    color:rgb(225, 221, 218);
    color: rgb(94,88,79);
    font-size: 26px;
    font-weight: normal;
    font-family: Helvetica;
    margin: 0;
}

@media screen and (max-width: 1200px) {
    .container-styles-portfolio {
        margin: 0 auto 20px auto;
        width: 90%;
    }

    .background-for-portfolio {
        width: 100%;
        margin: 0 auto 0px auto;
    }

    .portfolio-background {
        margin-top: 0;
        padding-top: 0;
    }

    .portfolio-background {
        background: rgb(248, 248, 243);
        margin: 0 0px 0 0px;
      }

      .project-title {
        width: 70%;
      }


}
