.construction-icon {
    font-size: 88px;
    color: bisque;
    margin-top: 20px;
}

h5 {
    color: white;
    font-size: 26px;
    font-family: Helvetica;
}

.text-sphere {
    position: relative;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
}

.tagcloud--item {
    color: rgb(255, 255, 255);
    // text-transform: uppercase;
}

.tagcloud--item:hover {
    color: #f8b2a5;
    color: rgb(94,88,79);
}

.skills {
    margin: auto;
    width: 100%;
}

.tagcloud {
    margin: auto;
    justify-content: center;
    align-items: center;
    font-family: 'Helvetica';
    font-weight: 600;
    letter-spacing: 0.0625em;
    font-size: 2em;
}

.text-sphere-container-mobile {
    display: none;
}

.mobile-sparkles {
    display: none;
}

.button {
    position: relative;
    display: inline-block;
    margin: 20px;
}

.button a {
    color: rgb(244, 244, 236);
    font-family: Helvetica, sans-serif;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
    text-decoration: none;
    background-color: rgb(105, 154, 141);
    background-color: rgb(151, 63, 68); 
    background-color: rgb(183, 80, 86); 
    display: block;
    position: relative;
    padding: 15px 40px;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    text-shadow: 0px 1px 0px #000;
    filter: dropshadow(color=#000, offx=0px, offy=1px);

    -webkit-box-shadow: inset 0 1px 0 rgb(105, 154, 141), 0 10px 0 rgb(4, 60, 45);
    -moz-box-shadow: inset 0 1px 0 rgb(105, 154, 141), 0 10px 0 rgb(4, 60, 45);
    box-shadow: inset 0 1px 0 rgb(105, 154, 141), 0 10px 0 rgb(4, 60, 45);

    -webkit-box-shadow: inset 0 1px 0 rgb(189, 118, 122), 0 10px 0 rgb(159, 58, 64);
    -moz-box-shadow: inset 0 1px 0 rgb(189, 118, 122), 0 10px 0 rgb(159, 58, 64);
    box-shadow: inset 0 1px 0 rgb(189, 118, 122), 0 10px 0 rgb(159, 58, 64);

    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}

.button a:active {
    top: 10px;

    -webkit-box-shadow: inset 0 1px 0 rgb(105, 154, 141), inset 0 -3px 0 rgb(4, 60, 45);
    -moz-box-shadow: inset 0 1px 0 rgb(105, 154, 141), inset 0 -3pxpx 0 rgb(4, 60, 45);
    box-shadow: inset 0 1px 0 rgb(105, 154, 141), inset 0 -3px 0 rgb(4, 60, 45);

    -webkit-box-shadow: inset 0 1px 0 rgb(189, 118, 122), inset 0 -3px 0 rgb(128, 39, 43);
    -moz-box-shadow: inset 0 1px 0 rgb(189, 118, 122), inset 0 -3pxpx 0 rgb(128, 39, 43);
    box-shadow: inset 0 1px 0 rgb(189, 118, 122), inset 0 -3px 0 rgb(128, 39, 43);


}

.button:after {
    content: "";
    height: 100%;
    width: 100%;
    padding: 4px;
    position: absolute;
    bottom: -15px;
    left: -4px;
    z-index: -1;
    background-color: rgb(31, 61, 59);
    background-color: rgb(128, 39, 43);
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    border-radius: 5px;
}


@media screen and (max-width: 700px) {

    .text-sphere-container-mobile {
        display: unset;
    }

    .text-sphere-container {
        display: none;
    }

    .tagcloud {
        font-size: 1.3em;
    }

    .regular-sparkles {
        display: none;
    }

}