.slider {
  position: relative;
  height: 100%;
}

.projectSlide {
  width: 100%;
  height: 500px;
  object-fit: contain;
  margin-top: 10px;
  border-radius: 0px;
  background-position: center;
}

.photoSlide {
  width: 100%;
  height: 620px;
  object-fit: cover;
  margin-top: 10px;
  border-radius: 5px;
  background-position: center;
}

.left-arrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  left: -8%;
  z-index: 1;
  cursor: pointer;
  width: 45px;
  height: auto;
}

.arrow-space :hover {
  color: white;
}

h4 {
  font-size: 18px;
  color: rgb(225, 221, 218);
  color: rgb(94, 88, 79);
  font-family: Arial;
  font-weight: normal;
}

.right-arrow {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: -8%;
  z-index: 1;
  cursor: pointer;
  width: 45px;
  height: auto;
}

.spacing-for-subtext {
  margin-bottom: 0px;
  margin-top: 10px;
  background: rgb(248, 248, 243);
  padding: 10px 20px;
  border-radius: 0px;
}

@media screen and (max-width: 700px) {
  .left-arrow {
    left: -12%;
    width: 35px;
    height: auto;
  }

  .right-arrow {
    right: -12%;
    width: 35px;
    height: auto;
  }

  .projectSlide {
    width: 100%;
    height: 350px;
    object-fit: contain;
    margin-top: 10px;
    border-radius: 0px;
    background-position: center;
  }

  .photoSlide {
    width: 100%;
    height: 300px;
    object-fit: cover;
    margin-top: 10px;
    border-radius: 5px;
    background-position: center;
  }

}