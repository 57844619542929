.page {
  width: 100%;
  height: 100%;
  position: absolute;
  overflow: auto;
  padding-top: 0%;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
}

/* Track */
::-webkit-scrollbar-track {
  //updated color
  background: rgb(244, 244, 236);
  border: none;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  //updated color
  background: rgb(195, 186, 169);
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  //updated color
  background: rgb(166, 153, 146);
}

.container {
  width: 100%;
  will-change: contents;
  min-height: 566px;
  opacity: 0;
  top: 5%;
  margin: 0 auto;
  z-index: 1;
  transform-style: preserve-3d;
  animation: fadeIn 1s forwards;
  animation-delay: 1s;
}

.container.contact-page,
.container.about-page,
.container.portfolio-page,
.container.skills-page {

  h1 {
    display: flex;
    justify-content: center;
    font-size: 53px;
    font-family: 'Coolvetica';
    //color of all header text by default
    color: rgb(236, 147, 127);
    color: rgb(193, 164, 141);
    color: rgb(130, 125, 118);    
    font-weight: 400;
    margin-top: 0;
    position: relative;
    margin-bottom: 20px;
    text-align: auto;
  }

  p {
    font-size: 18px;
    color: rgb(94, 88, 79);
    font-family: sans-serif;
    font-weight: 300;
    max-width: fit-content;
    margin: auto;
    margin-top: 120px;
    margin-right: 15%;
    margin-left: 15%;
    text-align: justify;
    animation: pulse 1s;

    &:nth-of-type(1) {
      animation-delay: 1.1s;
    }

    &:nth-of-type(2) {
      animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
      animation-delay: 1.3s;
    }
  }

  .text-zone {
    position: absolute;
    text-align: center;
    left: 130px;
    right: 30px;
    top: 50%;
    transform: translateY(-50%);
    vertical-align: middle;
    display: table-cell;
    max-height: 90%;
  }


}

.container.about-page,
.container.skills-page {
    .text-animate-hover {
    &:hover {
      color: rgb(244,244,236);
    }
  }
}

.container.contact-page,
.container.portfolio-page {
  .text-animate-hover {
    &:hover {
      color: rgb(151,63,68);
    }
  }
}

@media screen and (max-width: 1200px) {
  .page {
    height: calc(100% - 100px);
  }

  .container.home-page,
  .container.contact-page,
  .container.about-page,
  .container.portfolio-page,
  .container.skills-page {
    p {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .container {
    transform: translateY (50%);
    min-height: auto;
    margin-top: 5%;

    &.home-page,
    &.contact-page,
    &.about-page,
    &.portfolio-page,
    &.skills-page {
      .text-zone {
        display: flex;
        transform: none;
        width: 90%;
        display: block;
        padding: 10px;
        box-sizing: border-box;
        left: 0px;
        right: 0px;
        margin: auto;
      }

    }

    &.portfolio-page {
      width: 100%;

      h1.page-title {
        margin-left: 20px;
        margin-top: 20px;
      }

      .image-box {
        height: 200px;
        max-width: calc(50% - 10px);
      }
    }
  }

  // .stage-cube-cont {
  //   display: flex;
  //   position: initial;
  //   width: 100%;
  //   height: 0%;
  // }
}