.contact-form,
.left-sided {
    width: 50%;
    margin-top: 20px;

    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            opacity: 0;
            overflow: hidden;
            display: block;
            clear: both;
            position: relative;
            animation: fadeInUp 2s 2s;
            animation-fill-mode: forwards;
        }

        li.half {
            width: 49%;
            margin-left: 2%;
            float: left;
            clear: none;

            &:first-child {
                margin-left: 0;
            }
        }
    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        //updated colors below
        background: rgb(79, 121, 110);
        background:rgb(233,228,219);
        height: 50px;
        font-size: 16px;
        color: #fff;
        color: rgb(94,88,79);        
        padding: 0 20px;
        box-sizing: border-box;
    }

    input::placeholder,
    textarea::placeholder {
        opacity: 0.8;
                //updated colors below
        color: lightgray;
        color:rgb(158, 154, 147);
    }

    input:focus,
    textarea:focus {
        outline-color: white;
        outline-width: 10px;
    }

    textarea {
        width: 100%;
        border: 0;
        background:rgb(233,228,219);
        height: 50px;
        font-size: 16px;
        color: #fff;
        color: rgb(94,88,79);
        padding: 20px;
        box-sizing: border-box;
        min-height: 150px;
    }

    .flat-button {
        color:rgb(94, 88, 79);
        font-weight: bold;
        font-size: 15px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 8px 10px;
        border: none;
        border: 1.5px solid rgb(244,244,236);
        border-radius: 4px;
        background-color: rgb(200, 213, 201);
        text-transform: uppercase;
        float: right;
        text-align: center;
        margin-right: 10px;
    }
}

.flat-button:hover {
    background-color: rgb(236, 147, 127);
    background-color: rgb(193, 164, 141);
    background-color: rgb(151, 63, 68);
    color: rgb(244,244,236);
}

.map-wrap {
    background: rgb(151,168,152, 0.5);
    float: right;
    width: 45%;
    height: 115%;
    margin-top: 0px;
}

.leaflet-container {
    position: relative;
    width: 100%;
    height: 105vh;
    opacity: 0;
    animation: backInRight 1s 1.2s;
    animation-fill-mode: forwards;
}

h3 {
    font-size: 18px;
    color: rgb(94, 88, 79);
    font-family: sans-serif;
    font-weight: 300;
    margin-top: 100px;
    animation: pulse 1s;

    &:nth-of-type(1) {
        animation-delay: 1.1s;
    }

    &:nth-of-type(2) {
        animation-delay: 1.2s;
    }

    &:nth-of-type(3) {
        animation-delay: 1.3s;
    }
}

.info-map {
    position: absolute;
    background: rgb(151, 63, 68);
    background: rgb(166,153,146);
    background: rgb(94,88,79);
    background: rgb(58,84,110);
    top: 50px;
    right: 15px;
    z-index: 999999;
    width: 267px;
    padding: 20px;
    color: rgb(244,244,236);
    font-family: 'Helvetica';
    font-size: 17px;
    font-weight: 700;
    opacity: 0;
    animation: fadeIn 1s 1.5s;
    animation-fill-mode: forwards;

    span {
        font-size: 16px;

        span {
        color: rgb(236, 147, 127);
        }
    }
}

h8{
    color:rgb(244,244,236);
}


@media screen and (max-width: 1200px) {


    .contact-form,
    .left-sided {
        display: flex;
        width: 100%;
        margin-top: 20px;
        margin-left: 0;
        left:0;
        margin-right: 0;
    }
.contact-page {
    .text-zone {
        left:0;
    }
}

    form {
        position: relative;
        width: 100%;
    }

    .leaflet-container {
        display: none;
    }

    .map-wrap {
        float: none;
        margin: 0;
        width: 100%;
        height: 400px;
        display: none;
    }

    .info-map {
        position:absolute;
        right: 25%;
        margin-top: 500px;
        width: 50%;
        // display: none;
    }
}