.home-page {
  .text-zone {
    position: center;
    text-align: center;
    margin: 3% 0 0 100px;
    // top: 50%;
    //  max-height: 90%;
  }

  h1 {
    //updated colors below
    color: rgb(244, 244, 236);
    color: rgb(151, 168, 152);
    color: rgb(130, 125, 118);
    font-size: min(max(22px, 6vw), 65px);
    margin: 0;
    font-family: 'Coolvetica';
    font-weight: 400;

    .portrait {
      opacity: 0;
      min-width: 260px;
      width: 80%;
      max-width: 450px;
      border-radius: 5%;
      height: auto;
      animation: fadeIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h3 {
    color: rgb(233, 228, 219);
    color: rgb(166, 153, 146);
    color: rgb(130, 125, 118);
    color: rgb(151, 168, 152);
    margin-top: 20px;
    font-weight: 400;
    font-size: min(max(20px, 3vw), 45px);
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 2.8s backwards;
  }

  .made-with-love {
    display: flex;
    padding-top: 30px;
    margin-left: 100px;
    justify-content: center;
  }

  .icon {
    font-size: 40px;
    color: rgb(233, 228, 219);
    color: rgb(151, 63, 68);
    padding-right: 15px;
    animation: pulse 0.75s infinite;
    :pulse {
      font-size: 105px;
    }
  }

  h9 {
    font-family: cursive;
    font-size: 24px;
    color: rgb(130, 125, 118);
    font-weight: normal;
  }

  // .video-container {
  //   border-radius: 10px;
  //   // padding: 20px 0 20px 0;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   text-align: center;
  // }

  // .video-box {
  //   border-radius: 15px;
  //   margin-left: 20px;
  //   transform: translateY(-20px);
  //   // justify-content: center;
  //   // align-items: center;
  // }

  .button-space {
    display: flex;
    justify-content: center;
  }

  .home-background {
    background: rgb(197, 207, 198);
    margin: 5% 40px 0px 160px;
    border-radius: 0px;
    padding-top: 20px;
  }

  button {
    //updated colors on bottom
    color: rgb(61, 94, 85);
    color: rgb(137, 157, 139);
    background-color: rgb(255, 234, 206);
    background-color: rgb(233, 228, 219);
    font-size: min(max(15px, 4vw), 35px);
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    //two border options for button below
    border: 2px solid rgb(244, 244, 236);
    border: none;
    border-radius: 5px;
    margin-top: 10px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    margin-bottom: 60px;
    cursor: pointer;

    &:hover {
      background: rgb(151, 63, 68);
      color: rgb(244, 244, 236);
      transition: 0.4s ease-in-out;
    }
  }
}

@media screen and (max-width: 1200px) {
  .home-page {
    .text-zone {
      top: 50%;
    }

    h9 {
      font-size: 18px;
    }

    .icon {
      font-size: 25px;
    }

    .made-with-love {
    margin: auto;
    }
  }

  .home-background {
    margin: 0 40px;
  }

}

@media screen and (max-width: 500px){
  .home-page {
  h9 {
    font-size: 14px;
  }

  .made-with-love {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sparkles {
    display: none;
  }
}
}